.TopBar
{
  background-color: #0a568d;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding-left: 1rem;  
  padding-right: 1rem;  
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  font-size: larger;
  /*margin-bottom: 2rem;*/
}
.p-button
{
    padding: 0.5rem 0.5rem;
    background-color: #0a568d;
    color:#00adef;
    border-radius: 0.5rem;
    margin: 0.2rem;
    white-space: nowrap;   
}

.p-button-icon
{
padding:0;
}

.dati .p-inputtext{
  width: 100%;
}


.icobutton
{
    padding: 0.2rem 0.2rem;
    background-color: #0a568d;
    color:#00adef;  
}
.divHome
{
    width: 100vw;
    display: flex;
    flex-direction: column;
  /*  justify-content: center; */  
    align-items: center;
    height: 85vh;
    text-align: center;
    margin-top: 2rem;
}
.Titolo
{
    font-size: 2.2rem;
    color: #0a568d;
    font-weight: bolder;
    margin: 2.5rem;
}


.Titolo5
{
    font-size: 1.5rem;
    color: #0a568d;
    font-weight: bolder;
    margin: 0.2rem;
}



.Titolo2
{
    font-size: 1.5rem;
    color: #0a568d;
    font-weight: bold;
    margin: 2.5rem;
}
.Titolo3
{
    font-size: 1rem;
    color: #707070;
    font-weight: bold;
    margin:0.2rem;
    flex:1;

}
.bottoneHome-label
{
    margin-left: 0.5rem;
    font-weight: 600;
}
.listaItem
{
    color: #0a568d;
    padding: 0.3em;   
}
.listaItem:nth-child(odd)
{
    background-color: #f5f5f5;   
}
.listaItem:nth-child(even)
{
    background-color: #e1f2f9;   
}
.dati{
    padding: 0.2rem;
    display: flex;
    flex-direction: column;
    margin-right: 0.3rem;
    margin-bottom: 0.3rem;
    float: left;
}
.p-dialog, p.dialog-header,p.dialog-content
{
  background-color: white;
  color: #00adef;  
  border-color: #0a568d;
  border-radius: 10px;
  border-width: 2px;
}
.p-dialog .p-dialog-header
{
  background-color: white;
  color: #00adef;  
  font-weight: 900;
  font-size: 2rem;
}
.p-dialog .p-dialog-footer
{
  background-color: white;
  color: #00adef;  
  font-weight: 900;
  font-size: 2rem;
}
.p-confirm-dialog
{
  background-color: white;
  color: #00adef;  
  border-color: #0a568d;
  border-radius: 10px;
  border-width: 2px;
  border: solid;
  max-width: 85%;
  padding: 1.5em;
}
.p-dialog .p-dialog-content
{
  background-color: white;
  color: #00adef;  
  font-size: 1.3rem;
}
.p-confirm-dialog button{
    width: 4em;
}


.p-icon-field
{
  display: flex;
  align-items: center;
  padding: 0;
  padding-left: 0.3rem;
  
  
    border-radius: 10px;
}

.p-icon-field .p-inputnumber
{
  margin:0px;
  margin-left: 0.3rem;
}
.p-icon-field-left > .p-inputtext {
padding-left: 2rem;
}

.p-disabled, .p-component:disabled {
  opacity: 1;
  color: #888888 !important;
}
input:disabled {
  cursor: default;
  color: #888888 !important;
}
.p-disabled .p-dropdown-label
{
  cursor: default;
  color: #888888 !important;
}

.listaConv
{
  color:#2E75B6;
  background-color: transparent;     
  width:100%;
  column-count:2;
  padding:12px;
  -webkit-column-count: 2;
   -moz-column-count: 2;
   padding-top:15px;
   column-gap: 0;  
}


div.listaConv > div:nth-of-type(even)
{
  padding:5px 8px;
  background-color: #e1f2f9;   
}
div.listaConv > div:nth-of-type(odd)
{
  padding:5px 8px;
  background-color: white;   
}
